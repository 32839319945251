<template>
  <div class="warp">
    <van-form @submit="onSubmit">
      <van-field
        label-width="110"
        v-model="query.storeName"
        name="贮存点名称"
        label="贮存点名称"
        placeholder="请输入贮存点名称"
        :rules="[{ required: true, message: '请填写贮存点名称' }]"
      />
      <van-field
        readonly
        label-width="110"
        clickable
        name="compeny_store_type"
        :value="compeny_store_type"
        label="贮存类型"
        placeholder="点击选择贮存类型"
        @click="showPicker = true"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>
      <van-field
        label-width="110"
        v-model="query.storeArea"
        name="贮存点大小"
        label="贮存点大小(㎡)"
        placeholder="请输入贮存点大小"
      />
      <van-field
        label-width="110"
        v-model="query.storeCapacity"
        name="贮存能力"
        label="贮存能力(t)"
        placeholder="请输入贮存能力"
      />
      <van-field
        label-width="110"
        v-model="query.managerName"
        name="责任人"
        label="责任人"
        placeholder="请输入责任人"
      />
      <van-field
        label-width="110"
        v-model="query.phone"
        name="联系号码"
        label="联系号码"
        placeholder="请输入联系号码"
      />
      <van-field
        label-width="110"
        v-model="query.address"
        name="地址"
        label="地址"
        placeholder="请输入地址"
      />
      <van-field
        label-width="110"
        v-model="query.remarks"
        rows="2"
        autosize
        label="留言"
        type="textarea"
        maxlength="50"
        placeholder="请输入留言"
        show-word-limit
      />
      <div class="btn">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
    <div class="btn">
      <van-button round block type="danger" v-if="isShowbtnsc" @click="delClick">删除</van-button>
    </div>
    <div class="btn">
      <van-button round block type="default" @click="goBackClick">返回</van-button>
    </div>
  </div>
</template>

<script>
import { companystore, editcompanystore, delcompanystore } from '@/api/order.js'
import { dictType } from '@/api/notice.js'
import { Toast } from 'vant'
export default {
  data () {
    return {
      isShowbtnsc: false,
      query: {
        id: undefined, // id
        storeName: undefined, // 贮藏点名称
        storeType: undefined, // 贮藏点类型 1仓库 2其他
        storeArea: undefined, // 贮藏点面积大小，单位，平方
        storeCapacity: undefined, // 贮藏能力 单位：吨 
        managerName: undefined, // 负责人姓名
        phone: undefined, // 联系电话
        address: undefined, // 地址
        remarks: undefined, // 备注
      },
      columns: [],
      showPicker: false,
      compeny_store_type: undefined, // 保存贮藏点类型
    }
  },
  created() {
    this.dictType()
    if (this.$route.query.id) {
      this.isShowbtnsc = true
      this.editcompanystore()
    }
  },
  methods: {
    dictType() { // 字典
      dictType('compeny_store_type').then(res => { // 贮藏点类型
        if (res.data.code === 200) {
          this.columns = res.data.data.map(item => {
            return {
              id: item.dictValue,
              text: item.dictLabel
            }
          })
        }
      })
    },
    editcompanystore() { // 查看
      editcompanystore(this.$route.query.id).then(res => {
        if (res.data.code === 200) {
          this.query = res.data.data
          if (this.query.storeType === 1) {
            this.compeny_store_type = '仓库'
          } else if (this.query.storeType === 2) {
            this.compeny_store_type = '其他'
          }
        }
      })
    },
    onConfirm(value) {
      this.query.storeType = value.id
      this.compeny_store_type = value.text
      this.showPicker = false;
    },
    onSubmit(values) {
      if (this.query.storeType === '仓库') {
        this.query.storeType = 1
      } else if (this.query.storeType === '其他') {
        this.query.storeType = 2
      }
      companystore(this.query).then(res => {
        if (res.data.code === 200) {
          Toast('添加成功')
          this.$router.push({path: 'Storagepoint'})
        }
      })
    },
    delClick() {
      delcompanystore(this.$route.query.id).then(res => {
        if (res.data.code === 200) {
          Toast("删除成功")
          this.$router.push({path: 'Storagepoint'})
        }
      })
    },
    goBackClick() {
      this.$router.go(-1)
    }
  }
}

</script>
<style lang="scss" scoped>
.btn {
  padding: 7.5px 30px;
}
::v-deep .van-button--round {
  border-radius: 3px;
}
::v-deep .van-button--info {
  background-color: #0087f0;
  border: 1px solid #0087f0;
}
</style>
